/* eslint-disable import/order */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';

import MaterialTable from 'material-table';
import { Container } from './styles';

import useApi from '../../../hooks/useApi';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import './estilo.css';

export default function Pedido() {
  const { data } = useApi('/duvida');
  const [pedidos, setPedidos] = useState([]);
  const [columns, setColumns] = useState([
    { title: 'Nome', field: 'nome', editable: 'never' },
    {
      title: 'Unidade',
      field: 'unidade',
      editable: 'never',
    },
    {
      title: 'Aceitar',
      field: 'aceitar',
      lookup: { 0: 'Aberta', 1: 'Aceita', 2: 'Negada' },
    },
    {
      title: 'Pergunta',
      field: 'pergunta',
      editable: 'never',
    },
    {
      title: 'Resposta',
      field: 'resposta',
    },
  ]);

  const updateStatus = useCallback(async (id, aceitar, resposta) => {
    await api.put(`duvida/${id}`, {
      aceitar,
      resposta,
    });
    toast.success('Alterado com sucesso');
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }, []);

  return (
    <Container>
      <MaterialTable
        title="Listagem de perguntas"
        columns={columns}
        data={data?.duvidas}
        isLoading={!data}
        localization={{
          pagination: {
            nextAriaLabel: 'Próxima Página',
            nextTooltip: 'Próxima Página',
            lastAriaLabel: 'Página Anterior',
            lastTooltip: 'Página Anterior',
            labelRowsSelect: 'Linhas',
          },
          header: {
            actions: 'Ações',
          },
          body: {
            emptyDataSourceMessage: 'Não Há itens',
            editRow: {
              deleteText: 'Tem certeza de que deseja excluir?',
            },
          },
          toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
          },
        }}
        options={{
          filtering: true,
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...pedidos];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setPedidos([...dataUpdate]);
                updateStatus(newData.id, newData.aceitar, newData.resposta);
                resolve();
              }, 1000);
            }),
        }}
      />
    </Container>
  );
}
