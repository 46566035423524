/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Menu, MenuItem, Typography } from '@material-ui/core';

import './estilo.css';
import { Container } from './styles';
import api from '../../services/api';
import { Geral, Fases } from './json/cargos';
import { useHistory } from 'react-router';

interface cadidatosProps {
  nome: string;
  tipo: string;
  cargo: string;
  fase: string;
  anexo1: string;
  anexo2: string;
  anexo3: string;
}

const Header: React.FC = () => {
  const [menuPosition, setMenuPosition] = useState<any>(null);
  const history = useHistory();

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };
  const handleRightClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  return (
    <Navbar
      id="navbartab"
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ zIndex: 999 }}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Container>
          <div>
            <Navbar
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/');
              }}
            >
              Home
            </Navbar>
            <NestedMenuItem
              label="Eleição"
              parentMenuOpen={!!menuPosition}
              onClick={handleRightClick}
            >
              <NestedMenuItem
                label="Geral"
                parentMenuOpen={!!menuPosition}
                onClick={handleRightClick}
              >
                {Geral?.map((geral: any, index: any) => {
                  return (
                    <NestedMenuItem
                      key={index.toString()}
                      label={geral?.cargo}
                      parentMenuOpen={!!menuPosition}
                      onClick={handleRightClick}
                    >
                      {geral?.candidatos?.map((candidato: any, index: any) => {
                        return (
                          <NestedMenuItem
                            key={index.toString()}
                            label={candidato?.nome}
                            parentMenuOpen={!!menuPosition}
                            onClick={handleRightClick}
                          >
                            {candidato?.item?.map((anexo: any, index: any) => {
                              return !anexo?.type ? (
                                <MenuItem
                                  key={index.toString()}
                                  onClick={handleRightClick}
                                >
                                  <button
                                    onClick={() => {
                                      window.open(
                                        anexo?.anexo,
                                        '_blank',
                                        'noopener noreferrer',
                                      );
                                    }}
                                  >
                                    Visualizar
                                  </button>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key={index.toString()}
                                  onClick={handleRightClick}
                                >
                                  <a href={anexo?.anexo} target="_blank">
                                    Youtube
                                  </a>
                                </MenuItem>
                              );
                            })}
                          </NestedMenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                })}
              </NestedMenuItem>

              <NestedMenuItem
                label="Fase"
                parentMenuOpen={!!menuPosition}
                onClick={handleRightClick}
              >
                {Fases?.map((fase: any, index: any) => {
                  return (
                    <NestedMenuItem
                      key={index.toString()}
                      label={fase?.fase}
                      parentMenuOpen={!!menuPosition}
                      onClick={handleRightClick}
                    >
                      {fase?.cargos?.map((cargo: any, index: any) => {
                        return (
                          <NestedMenuItem
                            key={index.toString()}
                            label={cargo?.nome}
                            parentMenuOpen={!!menuPosition}
                            onClick={handleRightClick}
                          >
                            {cargo?.candidatos?.map(
                              (candidato: any, index: any) => {
                                return (
                                  <NestedMenuItem
                                    key={index.toString()}
                                    label={candidato?.nome}
                                    parentMenuOpen={!!menuPosition}
                                    onClick={handleRightClick}
                                  >
                                    {candidato?.item?.map(
                                      (anexo: any, index: any) => {
                                        return !anexo?.type ? (
                                          <MenuItem
                                            key={index.toString()}
                                            onClick={handleRightClick}
                                          >
                                            <button
                                              onClick={() => {
                                                window.open(
                                                  anexo?.anexo,
                                                  '_blank',
                                                  'noopener noreferrer',
                                                );
                                              }}
                                            >
                                              Visualizar
                                            </button>
                                          </MenuItem>
                                        ) : (
                                          <MenuItem
                                            key={index.toString()}
                                            onClick={handleRightClick}
                                          >
                                            <a
                                              href={anexo?.anexo}
                                              target="_blank"
                                            >
                                              Youtube
                                            </a>
                                          </MenuItem>
                                        );
                                      },
                                    )}
                                  </NestedMenuItem>
                                );
                              },
                            )}
                          </NestedMenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                })}
              </NestedMenuItem>
            </NestedMenuItem>
            <Navbar
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/duvidas');
              }}
            >
              Duvidas
            </Navbar>
          </div>
        </Container>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
