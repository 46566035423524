/* eslint-disable  */
import react, { useCallback, useEffect, useRef } from 'react';
import { Container, Content, Background } from './styles';
// @ts-ignore
import logo from '../../../assets/logo.png';
import Input from '../../../components/Input';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiMail, FiLock } from 'react-icons/fi';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { login, getToken } from '../../../services/auth';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

interface SignInFormData {
  email: string;
  senha: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const handleSubmit = useCallback(async (dados: SignInFormData) => {
    try {
      const { data } = await api.post('/auth', dados);
      login(data.token.token);
      toast.success('Bem-Vindo');
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (err) {
      toast.error('Não foi possível realizar o logOn');
    }
  }, []);

  useEffect(() => {
    console.log(getToken());
    if (getToken()) {
      history.push('/dashboard');
    }
  }, []);

  return (
    <Container>
      <Content>
        <img src={logo} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça seu logon</h1>
          <Input icon={FiMail} name="email" placeholder="E-mail" />
          <Input
            icon={FiLock}
            type="password"
            name="password"
            placeholder="Senha"
          />

          <Button>Entrar</Button>
        </Form>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
