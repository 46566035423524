/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
  Container,
  Content,
  ContainerTermos,
  ContainerTextoApoio,
  ContentTitle,
  ProductList,
} from './styles';
import Header from '../../../components/Header';
import Mulher from '../../../assets/muie.png';
import Logo from '../../../assets/logo.png';
import Map from '../../../assets/map.png';
import Mind from '../../../assets/mind.png';
import ModalCard from './Modal';
import EditalSelector from '../../../components/EditalSelector';

const Home: React.FC = () => {
  const [show, setShow] = useState(false);
  const [textoSelecionado, setTextoSelecionado] = useState(0);

  const [fase, setFase] = useState(0);

  const [title, setTitle] = useState('');
  const texto = [
    'No dia 18/03/2021 as pautas da Assembleia Geral Ordinária estarão disponíveis aos moradores através do site www.eleicoes.projetobandeirante.com.br, para análise de todo conteúdo relacionado as pautas, sendo a votação para presidente da Assembleia Geral Ordinária – Geral das 09h00 às 12h00, e as demais pautas do dia seguinte 19/03/2021 das 09h00 às 17h00, no Ginásio Poliesportivo do Condomínio. A assembleia semipresencial ficará disponível nos dias 18/03/2021 das 09h00 às 12h00 para Eleição de presidente da Assembleia Geral Ordinária no dia 19/03/2021 das 09h00 às 17h00 para as demais pautas.',
    'O voto será individual (um por unidade) através de cédulas que serão entregues após a conferência de um documento original com foto e assinatura da lista de presença de proprietários, sendo permitido o acesso apenas de um proprietário por unidade ao local de votação.',
    'O resultado da votação será comunicado após apuração, considerando as respectivas frações ideais atribuídas as unidades conforme redação da cláusula V.27, do Capitulo F, da Convenção de Condomínio.',
    'Importante reforçarmos que o formato semipresencial em horário ampliado, evita aglomerações, possibilitando a participação de todos os condôminos, a preservação e proteção da saúde de todos em virtude da pandemia pela Covid-19. ',
  ];

  return (
    <div>
      <Header />
      <Container>
        {show === true && (
          <ModalCard
            setShow={setShow}
            show={show}
            title={title}
            text={texto[textoSelecionado]}
          />
        )}
        <Content>
          <div>
            <img
              src={Logo}
              style={{
                width: 100,
                alignSelf: 'flex-start',
                marginLeft: 100,
                marginTop: 30,
              }}
            />
            <h2>
              Conheça o sistema de <h2>votação!</h2>
            </h2>
            <ContainerTextoApoio>
              <div />
              Confira abaixo o passo a passo <br />
              de como irá funcionar <br /> a nossa eleição <br />
            </ContainerTextoApoio>
          </div>
          <img src={Mulher} />
        </Content>

        <EditalSelector />
      </Container>
    </div>
  );
};

export default Home;
