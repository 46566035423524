import styled from 'styled-components';
import HOME from '../../../assets/HOME.jpg';
import MAP from '../../../assets/map.png';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url(${HOME});
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  > h2 {
    margin-top: 10%;
    align-self: center;
    font-size: 50px;
    text-align: center;
    color: #ccc;
    font-weight: 300;
  }
  > strong {
    font-weight: bold;
  }

  footer {
    width: 90%;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${MAP});
      background-repeat: no-repeat;
      background-size: cover;
      height: 500px;
      > img {
        margin-bottom: 15px;
      }
      > div {
        margin-left: 10px;
        height: 3px;
        width: 50%;
        background-color: #ff8000;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 426px) {
    > h2 {
      font-size: 2rem;
    }
    footer {
      > div {
        text-align: center;
        > img {
          width: 200px;
        }
      }
    }
  }
`;

export const Content = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  img {
    width: 55%;
  }
  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: flex-start;
    justify-content: flex-start;
    height: 100%;
    > h2 {
      margin-top: 20px;
      align-self: flex-end;
      width: 300px;
      line-height: 59px;
      font-size: 60px;
      margin-top: 15%;
      > h2 {
        color: #64a461;
        text-align: left;
        margin: 0;
        font-size: 60px;
      }
    }
  }
  @media (max-width: 769px) {
    > img {
      display: none;
    }
    > div {
      > h2 {
        margin-top: 20px;
        align-self: center;
        width: auto;
        line-height: 59px;
        font-size: 60px;
        margin-top: 15%;
        > h2 {
          color: #64a461;
          text-align: center;
          margin: 0;
          font-size: 60px;
        }
      }
    }
  }
  @media (max-width: 426px) {
    > div {
      > h2 {
        font-size: 2rem;
        > h2 {
          font-size: 2rem;
        }
      }
    }
  }
`;

export const ContainerTextoApoio = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  align-self: flex-end;
  justify-content: flex-start;
  text-align: justify;
  color: #ccc;

  > div {
    margin-left: 10px;
    width: 3px;
    height: 80px;
    background-color: #48cef7;
    margin-right: 10px;
  }
  @media (max-width: 769px) {
    align-self: flex-start;
    margin-left: 10%;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10%;
  > h1 {
    font-weight: bold;
  }

  > div {
    margin-left: 10px;
    width: 3px;
    height: 100%;
    background-color: #48cef7;
    margin-right: 10px;
  }

  > p {
    width: 500px;
    text-align: justify;
  }

  @media (max-width: 769px) {
    > p {
      width: 300px;
      text-align: justify;
    }
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

export const ProductList = styled.ul`
  margin-top: 10%;
  width: 50%;
  max-width: 800px;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  list-style: none;
  li {
    /* padding: 0 0px 40px 0px; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 244px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
    > strong {
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin-top: 5px;
      font-weight: bold;
      margin: 10px 40px;
      margin-top: 30px;
    }

    div {
      display: flex;
      align-items: baseline;
    }
    span {
      flex: 1;
      font-weight: 100;
      text-align: left;
      margin: 0px 40px;
    }
    button {
      margin-top: auto;
      width: 100px;
      align-self: center;
      margin-bottom: 20px;
      border: 0px;
      background-color: transparent;
      color: #22bdfd;
    }

    #risco {
      width: 100%;
      height: 10px;
      background-color: #22bdfd;
    }
  }

  @media (max-width: 1228px) {
    width: 90%;
    height: auto;
  }
  @media (max-width: 770px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContainerTermos = styled.div`
  width: 50%;
  margin-top: 10%;
  text-align: justify;
  /* bang do dick */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: #8fb0d0;
    font-size: 30px;
    border-radius: 30px;
    color: #fff;
    padding: 10px 0;
  }
  h2 {
    font-size: 25px;
    text-align: justify;
    margin-bottom: 20px;
  }
  li {
    list-style-type: none;
  }

  @media (max-width: 770px) {
    width: 80%;
    h2 {
      font-size: 20px;
    }
  }
`;
