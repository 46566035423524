import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export default function ModalDuvida({ show, setShow }) {
  const [nome, setNome] = useState('');
  const [unidade, setUnidade] = useState('');
  const [pergunta, setPergunta] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async data => {
    try {
      if (nome && unidade && pergunta) {
        console.log('1');
        await api.post('/duvida', {
          nome,
          unidade,
          pergunta,
        });
        setShow(false);
        toast.success('Sua pergunta foi enviada, passará por análise');
      } else {
        toast.error('Ops...Preencha todos os campos!');
      }
    } catch (err) {
      toast.error('Ops...Ocorreu um erro');
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escreva sua duvida!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="nome"
                placeholder="Nome"
                maxLength={80}
                onChange={e => setNome(e?.target?.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Unidade</Form.Label>
              <Form.Control
                type="number"
                name="unidade"
                placeholder="Unidade"
                maxLength={20}
                onChange={e => setUnidade(e?.target?.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Duvida</Form.Label>
              <textarea
                onChange={e => setPergunta(e?.target?.value)}
                className="form-control"
                name="Text1"
                cols="40"
                rows="5"
                maxLength={265}
              ></textarea>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
