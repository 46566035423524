import axios from 'axios';
import { getToken } from './auth';
// 'http://162.214.54.8:3395/'
//  http://162.214.160.241:3314
// 'https://eleicoesprojetobandeirante.com.br/api/'
const api = axios.create({
  baseURL:
    'https://test.mindconsulting.com.br/condominio-bandeirantes-eleicao/',
  // baseURL: 'http://localhost:3333/',
});

api.interceptors.request.use(async (config: any) => {
  const token = getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
