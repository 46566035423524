export const Geral = [
  {
    cargo: 'RESULTADOS',
    candidatos: [
      {
        nome: 'PRESIDENTE',
        item: [
          {
            anexo: 'geral/resultados/Resultado_Presidente.pdf',
          },
        ],
      },
      {
        nome: 'GERAL',
        item: [
          {
            anexo: 'geral/resultados/Resultado_Geral.pdf',
          },
        ],
      },
    ],
  },
  // {
  //   cargo: 'PREVISÃO ORÇAMENTÁRIA',
  //   candidatos: [
  //     {
  //       nome: 'Previsão Orçamentária 2024',
  //       item: [
  //         {
  //           anexo: 'geral/PREVISAO_ORCAMENTARIA/Previsao_-_Geral.pdf',
  //           nome: 'Previsao Orcamentaria 2024 - Geral.pdf',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   cargo: 'PRESTAÇÃO DE CONTAS',
  //   candidatos: [
  //     {
  //       nome: 'Prestação de Contas 2023 - Geral',
  //       item: [
  //         {
  //           anexo: 'geral/PRESTACAO_DE_CONTAS/Prestacao_de_Contas_-_Geral.pdf',
  //           nome: 'Prestacao de Contas 2023 - Geral.pdf',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const Fases = [
  {
    fase: 'Fase 1',
    cargos: [
      {
        nome: 'RESULTADOS',
        candidatos: [
          {
            nome: 'PRESIDENTE',
            item: [
              {
                anexo: 'fase/fase1/resultados/Resultado_presidente_1.pdf',
              },
            ],
          },
          {
            nome: 'GERAL',
            item: [
              {
                anexo: 'fase/fase1/resultados/Resultado_Eleicao_Fase_1.pdf',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    fase: 'Fase 2',
    cargos: [
      {
        nome: 'RESULTADOS',
        candidatos: [
          {
            nome: 'PRESIDENTE',
            item: [
              {
                anexo: 'fase/fase2/resultados/Resultado_fase_2.pdf',
              },
            ],
          },
          {
            nome: 'GERAL',
            item: [
              {
                anexo: 'fase/fase2/resultados/Resultado_fase_2_final.pdf',
              },
            ],
          }
        ],
      }
    ],
  },
  {
    fase: 'Fase 3',
    cargos: [
      {
        nome: 'RESULTADOS',
        candidatos: [
          {
            nome: 'PRESIDENTE',
            item: [
              {
                anexo: 'fase/fase3/resultados/Resultado_Presidente_fase3.pdf',
              },
            ],
          },
          {
            nome: 'GERAL',
            item: [
              {
                anexo: 'fase/fase3/resultados/Resultado_Final_Fase_3.pdf',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    fase: 'Fase 4',
    cargos: [
      {
        nome: 'RESULTADOS',
        candidatos: [
          {
            nome: 'PRESIDENTE',
            item: [
              {
                anexo: 'fase/fase4/resultados/Resultado_presidente_fase_4.pdf',
              },
            ],
          },
          {
            nome: 'GERAL',
            item: [
              {
                anexo: 'fase/fase4/resultados/Resultado_fase_4.pdf',
              },
            ],
          }
        ],
      }
    ],
  },
  {
    fase: 'Fase 5',
    cargos: [
      {
        nome: 'RESULTADOS',
        candidatos: [
          {
            nome: 'PRESIDENTE',
            item: [
              {
                anexo: 'fase/fase5/resultados/Resultado_Presidente_FaseV.pdf',
              },
            ],
          },
          {
            nome: 'GERAL',
            item: [
              {
                anexo: 'fase/fase5/resultados/Resultado_Final_Fase_V.pdf',
              },
            ],
          },
        ],
      },
    ],
  },
];
