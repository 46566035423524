import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@font-face {
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Cera Pro Black Italic.otf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('../fonts/Cera Pro Black.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Cera Pro Bold.otf') format('embedded-opentype'), /* Super Modern Browsers */
       url('../fonts/Cera Pro Light.otf') format('embedded-opentype'), /* Modern Browsers */
       url('../fonts/Cera Pro Medium.otf') format('embedded-opentype'), /* Safari, Android, iOS */
       url('../fonts/Cera Pro Regular Italic.otf') format('embedded-opentype'); /* Legacy iOS */
}
    *{
        margin: 0; 
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    body{
        background-color: #ffff;
        color: #000;
        -webkit-font-smoothing: antialiased;
        font-family: 'Cera Pro';
    }
    body, input, button {
        font-size: 16px;
        font-family: 'Heebo', sans-serif ;

    }
    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 300;
    }
    button{
        cursor: pointer;
    }

`;
