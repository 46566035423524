import Geral from './Editais/geral';
import Fase1 from './Editais/fase1';
import Fase2 from './Editais/fase2';
import Fase3 from './Editais/fase3';
import Fase4 from './Editais/fase4';
import Fase5 from './Editais/fase5';
import { useState } from 'react';
import './styles.css';

const EditalSelector: React.FC = () => {
  const [fase, setFase] = useState(0);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div>
        Selecione o edital:
        <select
          value={fase}
          onChange={e => setFase(parseInt(e.target.value))}
          style={{
            width: 200,
            height: 40,
            borderRadius: 10,
            fontSize: 16,
            marginTop: 20,
            marginLeft: 20,
            marginBottom: 20,
            padding: 5,
          }}
        >
          <option value={0}>Geral</option>
          <option value={1}>Fase 1</option>
          <option value={2}>Fase 2</option>
          <option value={3}>Fase 3</option>
          <option value={4}>Fase 4</option>
          <option value={5}>Fase 5</option>
        </select>
      </div>

      <div className="edital">
        {fase === 0 && <Geral />}
        {fase === 1 && <Fase1 />}
        {fase === 2 && <Fase2 />}
        {fase === 3 && <Fase3 />}
        {fase === 4 && <Fase4 />}
        {fase === 5 && <Fase5 />}
      </div>
    </div>
  );
};

export default EditalSelector;
