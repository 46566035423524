import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const BoxResposta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  margin-top: 40px;
  border: 1px solid #ccc;
  padding: 20px 40px;

  > strong {
    font-weight: bold;
    font-size: 25px;
  }

  & + div {
    margin-top: 20px;
  }

  @media (max-width: 780px) {
    width: 90%;
    > strong {
      font-size: 18px;
    }
  }

  @media (max-width: 450px) {
    width: 90%;
    > strong {
      font-size: 18px;
    }
  }
`;
