import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';

import { Container, BoxResposta } from './styles';
import Modal from './ModalDuvida';
import useApi from '../../../hooks/useApi';

function Duvidas() {
  const [show, setShow] = useState(false);
  const { data } = useApi('/duvida/question');

  return (
    <>
      <Header />
      <Container>
        {show && <Modal show={show} setShow={setShow} />}
        <a href="#" onClick={() => setShow(true)}>
          Enviar Duvida
        </a>
        {data?.duvidas?.map((duvida, index) => (
          <BoxResposta>
            <strong>{duvida?.pergunta}</strong>
            <span>{duvida?.resposta}</span>
          </BoxResposta>
        ))}
      </Container>
    </>
  );
}

export default Duvidas;
