/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
/* eslint-disable react/display-name */
import React, { ComponentType } from 'react';
import { Route, Redirect, RedirectProps } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../services/auth';

// Pages components
import SignIn from '../views/pages/SignIn';
import Home from '../views/pages/Home';
import Duvidas from '../views/pages/Duvidas';
import DuvidasDash from '../views/pages/DuvidasDash';

interface ReactPropsDefault extends RedirectProps {
  component?: React.ComponentType | any;
}

export const PrivateRoute: React.FC<ReactPropsDefault> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        getToken() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export const routes = {
  protected: [
    {
      title: 'Duvidas',
      path: '/dashboard',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />,
      component: DuvidasDash,
    },
  ],
  public: [
    {
      path: '/',
      icon: '',
      component: Home,
    },
    {
      path: '/login',
      icon: '',
      component: SignIn,
    },
    {
      path: '/duvidas',
      icon: '',
      component: Duvidas,
    },
  ],
};
