/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #fff;
  z-index: 999;
  div {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #fff;
  }

  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    div {
      width: 90px;
      flex-direction: column;
    }
  }
`;
